interface page {
  [key: string]: any;
}

export default {
  Main: () => import('@/components/main/main.vue'),
  home: () => import('@/views/home/home.vue'),

  /* 用户管理 */
  userList: () => import('@/views/userManagement/userList/userList.vue'), // 用户查询
  userLeaveInfo: () => import('@/views/userManagement/userLeaveInfo/userLeaveInfo.vue'), // 用户留资信息
  userLeaveInfoSnapshoot: () =>
    import('@/views/userManagement/userLeaveInfoSnapshoot/userLeaveInfoSnapshoot.vue'), // 用户留资快照
  apiChannelUser: () => import('@/views/userManagement/apiChannelUser/apiChannelUser.vue'), // api渠道用户撞库
  feedback: () => import('@/views/userManagement/feedback/feedback.vue'), // 意见反馈
  virtualNumber: () => import('@/views/userManagement/virtualNumber/virtualNumber.vue'), // 虚拟号段

  /* 投放管理 */
  channelList: () => import('@/views/channelManagement/channelList/channelList.vue'), // 渠道列表
  channelListSettlementField: () =>
    import('@/views/channelManagement/channelList/channelListSettlementField.vue'), // 渠道列表特殊权限
  pageList: () => import('@/views/channelManagement/pageList/pageList.vue'), // 渠道注册页
  channelGroup: () => import('@/views/channelManagement/channelGroup/channelGroup.vue'), // 渠道分组
  registerPageStatistics: () =>
    import('@/views/channelManagement/registerPageStatistics/registerPageStatistics.vue'), // 注册页数据统计
  channelStatistic: () => import('@/views/channelManagement/channelStatistic/channelStatistic.vue'), // 渠道数据统计
  channelStatisticSpecialAuthority: () =>
    import('@/views/channelManagement/channelStatistic/channelStatisticSpecialAuthority.vue'), // 渠道数据统计特殊权限
  channelAPIStatistic: () =>
    import('@/views/channelManagement/channelAPIStatistic/channelAPIStatistic.vue'), // 渠道数据统计
  dataOverview: () => import('@/views/channelManagement/dataOverview/dataOverview.vue'), // 数据概览
  domainManagement: () => import('@/views/channelManagement/domainManagement/domainManagement.vue'), // 运营位管理
  textType: () => import('@/views/channelManagement/textType/textType.vue'), // 文章管理
  textManagement: () => import('@/views/channelManagement/textManagement/textManagement.vue'), // 文章管理
  shortChain: () => import('@/views/channelManagement/shortChain/shortChain.vue'), // 短链管理
  ipManagement: () => import('@/views/channelManagement/ipManagement/ipManagement.vue'), // IP白名单
  collisionRulesConfig: () =>
    import('@/views/channelManagement/collisionRulesConfig/collisionRulesConfig.vue'), // 撞库规则配置
  channelStarData: () => import('@/views/channelManagement/channelStarData/channelStarData.vue'), // 渠道星级数据
  protocolConfig: () => import('@/views/channelManagement/protocolConfig/protocolConfig.vue'), // 协议配置
  defaultNameConfig: () =>
    import('@/views/channelManagement/defaultNameConfig/defaultNameConfig.vue'), // 非法姓名配置

  /* 贷超管理 */
  productList: () => import('@/views/productManagement/productList/productList.vue'), // 产品列表
  productIncome: () => import('@/views/productManagement/productIncome/productIncome.vue'), // 产品收益
  productStatement: () => import('@/views/productManagement/productStatement/productStatement.vue'), // 产品结算表
  operationBitManagement: () =>
    import('@/views/productManagement/operationBitManagement/operationBitManagement.vue'), // 运营位管理
  loanApiOrder: () => import('@/views/productManagement/loanApiOrder/loanApiOrder.vue'), // 网贷api申请订单
  channelProductConfig: () =>
    import('@/views/productManagement/channelProductConfig/channelProductConfig.vue'), // 渠道产品配置
  riskErrorLog: () => import('@/views/productManagement/riskErrorLog/riskErrorLog.vue'), // 风控失败日志
  productAssociationManagement: () =>
    import(
      '@/views/productManagement/productAssociationManagement/productAssociationManagement.vue'
    ), // 产品关联管理
  causeFailure: () => import('@/views/productManagement/causeFailure/causeFailure.vue'), // 进件失败原因

  /* 数据统计 */
  appLeaveBasePoint: () => import('@/views/dataManagement/appLeaveBasePoint/appLeaveBasePoint.vue'), // APP留资埋点
  appLeaveChannelPoint: () =>
    import('@/views/dataManagement/appLeaveChannelPoint/appLeaveChannelPoint.vue'), // APP留资渠道埋点
  popoverDataStatistics: () =>
    import('@/views/dataManagement/popoverDataStatistics/popoverDataStatistics.vue'), // 弹窗数据埋点
  negativeScreenPoint: () =>
    import('@/views/dataManagement/negativeScreenPoint/negativeScreenPoint.vue'), // 负一屏数据埋点
  baffleDataStatistics: () =>
    import('@/views/dataManagement/baffleDataStatistics/baffleDataStatistics.vue'), // 挡板数据埋点
  baffleList: () => import('@/views/dataManagement/baffleList/baffleList.vue'), // 挡板配置

  /* 助贷管理 */
  organizationList: () =>
    import('@/views/loanAssistanceManagement/organizationList/organizationList.vue'), // 机构列表
  organizationListAudit: () =>
    import('@/views/loanAssistanceManagement/organizationList/organizationListAudit.vue'), // 机构列表审核
  loanAssistanceProductList: () =>
    import(
      '@/views/loanAssistanceManagement/loanAssistanceProductList/loanAssistanceProductList.vue'
    ), // 助贷产品列表
  loanAssistanceProductCheck: () =>
    import(
      '@/views/loanAssistanceManagement/loanAssistanceProductCheck/loanAssistanceProductCheck.vue'
    ), // 助贷产品审核
  productCheck: () => import('@/views/loanAssistanceManagement/productCheck/productCheck.vue'), // 助贷产品审核(员工)
  loanAssistanceOrganizationStatistics: () =>
    import(
      '@/views/loanAssistanceManagement/loanAssistanceOrganizationStatistics/loanAssistanceOrganizationStatistics.vue'
    ), // 助贷机构统计
  loanAssistanceProductStatistics: () =>
    import(
      '@/views/loanAssistanceManagement/loanAssistanceProductStatistics/loanAssistanceProductStatistics.vue'
    ), // 助贷产品统计
  cityAcquisitionStatistics: () =>
    import(
      '@/views/loanAssistanceManagement/cityAcquisitionStatistics/cityAcquisitionStatistics.vue'
    ), // 城市获客统计
  acquisitionList: () =>
    import('@/views/loanAssistanceManagement/acquisitionList/acquisitionList.vue'), // 获客列表
  topUpOrderList: () =>
    import('@/views/loanAssistanceManagement/topUpOrderList/topUpOrderList.vue'), // 充值订单表
  loanAssistanceLog: () =>
    import('@/views/loanAssistanceManagement/loanAssistanceLog/loanAssistanceLog.vue'), // 助贷日志
  organizationAssociationManagement: () =>
    import(
      '@/views/loanAssistanceManagement/organizationAssociationManagement/organizationAssociationManagement.vue'
    ), // 机构关联管理
  organizationAudit: () =>
    import('@/views/loanAssistanceManagement/organizationAudit/organizationAudit.vue'), // 机构审核
  organizationAuditList: () =>
    import('@/views/loanAssistanceManagement/organizationAuditList/organizationAuditList.vue'), // 待审机构资料
  organizationInfo: () =>
    import('@/views/loanAssistanceManagement/organizationInfo/organizationInfo.vue'), // 机构资料
  lASortValStrategy: () =>
    import('@/views/loanAssistanceManagement/lASortValStrategy/lASortValStrategy.vue'), // 排序值策略
  issuerNotice: () => import('@/views/loanAssistanceManagement/issuerNotice/issuerNotice.vue'), // 发布机构公告
  organizationAuditFlow: () =>
    import('@/views/loanAssistanceManagement/organizationAuditFlow/index.vue'), // 机构资料审核流程

  /* 运营管理 */
  operateData: () => import('@/views/operateManagement/operateData/operateData.vue'), // 运营数据
  operatePositionManege: () =>
    import('@/views/operateManagement/operatePositionManege/operatePositionManege.vue'), // 运营位管理
  advertiserManage: () => import('@/views/operateManagement/advertiserManage/advertiserManage.vue'), // 广告商管理
  advertisementManage: () =>
    import('@/views/operateManagement/advertisementManage/advertisementManage.vue'), // 广告管理

  /* 系统管理 */
  adminUser: () => import('@/views/systemManagement/user/user.vue'), // 用户管理
  adminRole: () => import('@/views/systemManagement/role/role.vue'), // 角色管理
  sysModule: () => import('@/views/systemManagement/sysModule/sysModule.vue'), // 菜单管理
  agentManagement: () => import('@/views/systemManagement/agentManagement/agentManagement.vue'), // 代理商管理

  /* 通知模板管理 */
  notification: () => import('@/views/noticeManagement/notification/notification.vue'), // 通知管理

  /* 埋点管理 */
  pointList: () => import('@/views/pointManagement/pointList/pointList.vue'), // 数据录入
  statisticalTable: () => import('@/views/pointManagement/statisticalTable/statisticalTable.vue'), // 统计表管理

  /* 应用市场管理 */
  appInfo: () => import('@/views/appManagement/appInfo/appInfo.vue'), // APP应用列表

  /* 短信配置 */
  smsOperate: () => import('@/views/smsConfig/smsOperate/smsOperate.vue'), // 短信运营商
  sendSmsConfig: () => import('@/views/smsConfig/sendSmsConfig/sendSmsConfig.vue'), // 发送短信配置
  blacklistUser: () => import('@/views/smsConfig/blacklistUser/blacklistUser.vue'), // 黑名单用户
  /* 电子签配置 */
  supplierConfig: () => import('@/views/signConfig/supplierConfig/supplierConfig.vue'), // 电子签供应商
} as page;
