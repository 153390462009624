import logo_ from '@/assets/images/logo-title.png';
const runType = import.meta.env.APP_FLAG;

let apiPrefixPath = '';
let SIGNKEY = '';
const logo = logo_;
const title = '借易推';
export let ossPath = '';

/*
 * TODO 需要更换接口时需要修改的地方
 * */
switch (runType) {
  case 'pro': // 线上环境
    apiPrefixPath = 'https://gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test': // 外网测试环境
    apiPrefixPath = 'https://test-gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default: // 默认环境
    // apiPrefixPath = 'https://gw.xiaoyiqb.cn/admin/';
    // ossPath = 'https://oss.xiaoyiqb.cn/';
    apiPrefixPath = 'https://test-gw.xiaoyiqb.cn/admin/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
}
export default {
  apiPrefixPath,
  SIGNKEY,
  ossPath,
  logo,
  title,
};
