import md5 from 'js-md5';
import { tsTypes } from '@/libs/interfaceType';
import api from '@/libs/api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS from 'crypto-js';
import basics from '@/libs/basics';

const basicsMethods = {
  // 统一处理formlist
  unifiedListFindValue(list: Array<tsTypes.ParamsUnKnown>, value: number | string, key: string) {
    return (
      list.find((item) => {
        return item[key] === value;
      }) || { text: '-' }
    );
  },
  // 签名
  createRequestSign(
    data: tsTypes.ParamsUnKnown = {},
    timestamps?: number | string,
    method?: string
  ): string {
    const keyList = Object.keys(data);
    let urlParams = '';
    if (method === 'get') {
      for (const key of keyList) {
        urlParams +=
          keyList[keyList.length - 1] !== key
            ? `${key}=${encodeURI(<string>data[key])}&`
            : `${key}=${encodeURI(<string>data[key])}`;
      }
    } else {
      urlParams = JSON.stringify(data);
    }
    urlParams += `${timestamps}${api.SIGNKEY}`;
    // console.log('--------urlParams-->', urlParams);
    return urlParams;
  },

  dataToString(data: any): string {
    const keyList = Object.keys(data);
    let urlParams = '';
    for (const key of keyList) {
      urlParams +=
        keyList[keyList.length - 1] !== key
          ? `${key}=${encodeURI(<string>data[key])}&`
          : `${key}=${encodeURI(<string>data[key])}`;
    }

    return urlParams;
  },

  mathMethod: {
    // 乘法
    multiply(arg1: number, arg2: number) {
      let m = 0;
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        m += (arg1Fractional && arg1Fractional.length) || 0;
        m += (arg2Fractional && arg2Fractional.length) || 0;
      } catch (e) {
        return NaN;
      }
      return (
        (Number(String(arg1).replace('.', '')) * Number(String(arg2).replace('.', ''))) /
        Math.pow(10, m)
      );
    },
    // 除法
    division(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const differenceMultiple = Math.pow(10, arg2Length - arg1Length);
        return this.multiply(
          Number(String(arg1).replace('.', '')) / Number(String(arg2).replace('.', '')),
          differenceMultiple
        );
      } catch (e) {
        return NaN;
      }
    },
    // 加法
    addition(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) + this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
    // 减法
    subtraction(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) - this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
  },

  // 将数值变为tree的结构
  treeDataClean: (items: Array<tsTypes.objAndAny>, fatherId: 0) => {
    const result: any[] = []; // 存放结果集
    const itemMap: tsTypes.objAndAny = {}; //
    for (const item of items) {
      const id = item.id;
      const pid = item.pid;

      if (!itemMap[id]) {
        itemMap[id] = {
          children: [],
        };
      }

      itemMap[id] = {
        ...item,
        children: itemMap[id]['children'],
      };

      const treeItem: any = itemMap[id];

      if (pid === fatherId) {
        result.push(treeItem);
      } else {
        if (!itemMap[pid]) {
          itemMap[pid] = {
            children: [],
          };
        }
        itemMap[pid].children.push(treeItem);
      }
    }
    return result;
  },

  /*
   * 数组过滤转换
   * @param list Array
   * @param val string | number 值
   * @param value string 数组中的value字段
   * @param text string 数组中的文本字段
   * */
  filterArray: (
    list: tsTypes.objAndAny[],
    val: string | number,
    text = 'text',
    value = 'value'
  ): string | any => {
    if (!list || !list?.length || basics.isNull(val)) return '';
    let obj: any = {};
    let result = '';
    if (typeof val === 'number') {
      obj = list.find((item: tsTypes.objAndAny) => item[value] == val) || {};
      result = obj[text];
    } else if (String(val).indexOf(',') > -1) {
      const temp: string[] = val.split(',');
      const textArr: string[] = [];
      temp.forEach((t: string) => {
        obj = list.find((item: tsTypes.objAndAny) => item[value] == t);
        console.log(obj, list);
        obj[text] && textArr.push(obj[text]);
      });
      result = textArr.join(',');
    } else {
      obj = list.find((item: tsTypes.objAndAny) => item[value] == val) || {};
      result = obj[text];
    }
    return result;
  },
};

export default basicsMethods;
