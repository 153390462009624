<template>
  <div :class="`login ${isPhone === 'PC' ? '' : 'login-wap'}`">
    <div class="login-con">
      <div>
        <div class="title">
          <img src="@/assets/images/logo.png" alt="" />
          <p>欢迎登录荟信钱包后台管理系统</p>
        </div>
        <div class="form-con">
          <login-form @on-success-valid="handleSubmit"></login-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import LoginForm from '_c/login-form';
  import adminConfig from '@/libs/config.js';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import { Auth, ChannelBusiness } from '@/libs/requestAddress';
  import { reactive } from 'vue';

  export default {
    components: {
      LoginForm,
    },
    setup() {
      const router = useRouter();
      const handleSubmit = (user: { phone: string; smsCode: string }, isMaster: number) => {
        const _url = isMaster === 0 ? Auth.login : ChannelBusiness.login;
        http.post(_url, user).then((res) => {
          basics.Storage.setSession(adminConfig.store.authorCacheName, {
            ...res,
            isPower: +res.roleId === 24 ? false : true, //是否有权限(上下架产品，查看渠道),商务人员无
          });
          router.replace({
            path: '/',
          });
        });
      };

      const state = reactive({
        isPhone: basics.isPhone(),
      });

      return {
        handleSubmit,
        ...toRefs(state),
      };
    },
  };
</script>
<style lang="less">
  @import './login.less';
</style>
