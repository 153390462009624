import api from './api.js';

const TimeArr: any = [];
for (let i = 0; i < 24; i++) {
  TimeArr.push({ text: i + '时', value: `${i}` });
}
const adminConfig = {
  otherConfiguration: {
    apiPrefixPath: api.apiPrefixPath,
    /* 默认线上请求地址 */
    uploadExcelUrl: '/customer/uploadExcel', // excel上传地址
    pictureUploading: '/upload/img', // 图片上传地址
    picturePrefix: 'image', // 图片前缀
  },
  requestParameters: {
    // 请求参数
    request_SUCCESS: 0, // 请求成功状态
    request_FAIL: 1, // 请求失败状态
    request_TOKEN_ERROR: 401, // 登录失效
    request_SIGN_ERROR: 402, // 签名校验失败
    request_AUTH_ERROR: 403, // 访问受限
    request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
    request_BLACK: -10, // 黑名单
    request_NOT_PERMISSION: -20, // 无权限访问
    request_TIMEOUT: -30, // 请求超时
    request_VISIT_OUT: -40, // 访问次数受限
    request_NOT_FOUND: 404, // 资源不存在
  },
  store: {
    authorCacheName: 'userLoginInfo',
  },
  componentName: {
    MyTips: 'MyTips',
    MyTitle: 'MyTitle',
    MyInput: 'MyInput',
    MySelect: 'MySelect',
    MySelectCity: 'MySelectCity',
    MySelectCityLocal: 'MySelectCityLocal',
    MyCityLocalSearch: 'MyCityLocalSearch',
    MyTreeSelect: 'MyTreeSelect',
    MyRadio: 'MyRadio',
    MyCheckbox: 'MyCheckbox',
    ImgUpload: 'ImgUpload',
    MyTime: 'MyTime',
    MyDateTime: 'MyDateTime',
    MyRadioTime: 'MyRadioTime',
    MyCustomFormItem: 'MyCustomFormItem',
    MyGuideConfig: 'MyGuideConfig',
    MyText: 'MyText',
    MyCustomAddInputInputItem: 'MyCustomAddInputInputItem',
    MyInputLinkInput: 'MyInputLinkInput',
    WarningConfig: 'WarningConfig',
    MyCustomLabel: 'MyCustomLabel',
    MyTinymce: 'MyTinymce',
  },
  table: {
    buttonType: {
      // table按钮功能
      otherEvents: 'otherEvents', // 按钮其他事件
      delete: 'Delete', // 删除功能按钮
      render: 'Render', // 自定义内容按钮
    },
  },
  commonList: {
    banner: [
      { text: 'banner', value: 1 },
      { text: '运营弹窗', value: 2 },
      { text: '橱窗', value: 3 },
    ],
    jumpType: [
      { text: '内链', value: 1 },
      { text: '外链', value: 2 },
    ],
    jumpTypeMore: [
      { text: '内链', value: 1 },
      { text: '外链', value: 2 },
      // { text: '广告', value: 3 },
    ],
    productType: [
      { text: '网贷产品', value: 1 },
      { text: '助贷产品', value: 2 },
    ],
    processList: [
      { text: 'H5产品', value: 1, disabled: false },
      { text: '联登产品', value: 2, disabled: false },
      { text: '信贷api', value: 3, disabled: false },
      { text: '网贷api', value: 4, disabled: false },
    ],
    isMobileSupport: [
      { text: '安卓', value: 'android' },
      { text: '苹果', value: 'ios' },
    ],
    applicationStatus: [
      { text: '申请成功', value: 1 },
      { text: '申请失败', value: 2 },
    ],
    // 渠道列表-状态
    channelStatus_: [
      {
        text: '下线',
        value: 0,
      },
      {
        text: '上线',
        value: 1,
      },
    ],
    // 显示状态
    ifHideList: [
      {
        text: '未隐藏',
        value: 0,
      },
      {
        text: '已隐藏',
        value: 1,
      },
    ],
    domainList: [
      {
        text: '留资渠道页面访问地址',
        value: 10,
      },
      {
        text: '注册下载渠道页面访问地址',
        value: 11,
      },
      {
        text: 'OSS文件',
        value: 20,
      },
      {
        text: 'App接口地址',
        value: 30,
      },
      {
        text: 'Web接口地址',
        value: 40,
      },
      {
        text: '管理端接口访问地址',
        value: 50,
      },
      {
        text: 'wap页面访问地址',
        value: 60,
      },
    ],
    domainType: [
      {
        text: '留资渠道',
        value: 10,
      },
      {
        text: '注册下载渠道',
        value: 11,
      },
    ],
    // 渠道列表-add - 城市限定
    cityEnable: [
      { text: '准入城市', value: 1 },
      { text: '禁用城市', value: 2 },
    ],
    jumpTypeSuccess: [
      { text: '默认跳转下游逻辑', value: 1 },
      { text: '动态跳转上游', value: 2 },
      { text: '固定跳转下游', value: 3 },
    ],
    // 选择是否
    isControl: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
    creditApiType: [
      {
        text: '半流程',
        value: 1,
      },
      {
        text: '全流程',
        value: 2,
      },
    ],
    agreementType: [
      {
        text: '静态协议',
        value: 1,
      },
      {
        text: '动态协议',
        value: 2,
      },
    ],
    onlineStatus: [
      {
        text: '正常',
        value: 0,
      },
      {
        text: '停用',
        value: 1,
      },
    ],
    sex: [
      {
        text: '男',
        value: 0,
      },
      {
        text: '女',
        value: 1,
      },
    ],
    sex2: [
      {
        text: '男',
        value: 1,
      },
      {
        text: '女',
        value: 0,
      },
    ],
    osDisplay: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: '展示Android',
        value: 1,
      },
      {
        text: '展示iOS',
        value: 2,
      },
    ],
    osTypeDisplay: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: 'H5',
        value: 1,
      },
      {
        text: 'APP',
        value: 2,
      },
    ],
    openDetail: [
      {
        text: '不开启',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    limitCityType: [
      {
        text: '不限',
        value: 0,
      },
      {
        text: '准入',
        value: 1,
      },
      {
        text: '禁入',
        value: 2,
      },
    ],
    protocolType: [
      {
        text: '展示我司协议',
        value: 1,
      },
      {
        text: '展示我司协议+产品协议',
        value: 2,
      },
    ],
    nullParam: [
      {
        text: '不展示',
        value: 0,
      },
      {
        text: '展示',
        value: 1,
      },
    ],
    withdrawalWay: [
      {
        text: '三方H5提现',
        value: 1,
      },
      {
        text: '三方下载提现',
        value: 2,
      },
      {
        text: '站内提现',
        value: 3,
      },
    ],
    apiType: [
      {
        text: '半流程',
        value: 1,
      },
      {
        text: '全流程',
        value: 2,
      },
    ],
    hoursList: TimeArr,
    recordType: [
      {
        text: '充值',
        value: 1,
      },
      {
        text: '退款',
        value: 2,
      },
      {
        text: '获客',
        value: 3,
      },
    ],
    payType: [
      {
        text: '手动',
        value: 0,
      },
      {
        text: '微信',
        value: 1,
      },
      {
        text: '支付宝',
        value: 2,
      },
    ],
    aidCategory: [
      {
        text: '房主贷',
        value: 1,
      },
      {
        text: '车主贷',
        value: 2,
      },
      {
        text: '公积金贷',
        value: 3,
      },
      {
        text: '保单贷',
        value: 4,
      },
      {
        text: '信用贷',
        value: 5,
      },
      {
        text: '社保贷',
        value: 6,
      },
    ],
    category: [
      {
        text: '助贷产品',
        value: 0,
      },
      {
        text: 'H5产品',
        value: 1,
      },
      {
        text: '联登产品',
        value: 2,
      },
      {
        text: '信贷API产品',
        value: 3,
      },
      {
        text: '网贷API产品',
        value: 4,
      },
    ],
    shelfCycle: [
      {
        text: '每日上线',
        value: 1,
      },
      {
        text: '工作日上线（周一到周五）',
        value: 2,
      },
      {
        text: '周六周末上线',
        value: 3,
      },
      {
        text: '周六上线',
        value: 4,
      },
      {
        text: '周末上线',
        value: 5,
      },
    ],
    weekList: [
      {
        text: '周一',
        value: 1,
      },
      {
        text: '周二',
        value: 2,
      },
      {
        text: '周三',
        value: 3,
      },
      {
        text: '周四',
        value: 4,
      },
      {
        text: '周五',
        value: 5,
      },
      {
        text: '周六',
        value: 6,
      },
      {
        text: '周天',
        value: 7,
      },
    ],
    bannerList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心上',
        value: 2,
      },
    ],
    operationList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心',
        value: 2,
      },
    ],
    shopWindowList: [
      {
        text: '个人中心弹窗',
        value: 1,
      },
      {
        text: 'app退出拦截弹窗',
        value: 2,
      },
    ],
    implType: [
      {
        text: '阿里云短信',
        value: 1,
      },
      {
        text: '极光push实现',
        value: 2,
      },
      {
        text: '邮件实现',
        value: 3,
      },
    ],
    keyList: [
      { text: '埋点', value: 1 },
      { text: '参数', value: 2 },
      { text: '非去重表列', value: 3 },
    ],
    auditStatusList: [
      { text: '待提审', value: 0 },
      { text: '审核中', value: 1 },
      { text: '审核成功', value: 2 },
      { text: '审核失败', value: 3 },
    ],
    auditStatusListOne: [
      { text: '待审核', value: 0 },
      { text: '审核成功', value: 1 },
      { text: '审核失败', value: 2 },
    ],
    auditStatusListTwo: [
      { text: '审核中', value: 0 },
      { text: '审核成功', value: 1 },
      { text: '审核失败', value: 2 },
    ],
    auditStatusListThree: [
      { text: '审核中', value: 1 },
      { text: '审核失败', value: 2 },
      { text: '审核成功', value: 3 },
    ],
    auditStatusListFour: [
      { text: '审核中', value: 1 },
      { text: '审核成功', value: 2 },
      { text: '审核失败', value: 3 },
    ],
    authFlow: [
      { text: '实名认证', value: 1, disabled: true },
      { text: '联系人认证', value: 2 },
      { text: '补充信息认证', value: 3 },
      { text: '银行卡认证', value: 4 },
      { text: '第三方自定义认证', value: 5 },
    ],
    isBankType: [
      { text: '静态银行列表', value: 1 },
      { text: '动态银行列表', value: 2 },
    ],
    applySource: [
      { text: 'APP个人中心返回拦截弹窗', value: 'app_user_callback' },
      { text: 'APP返回拦截弹窗', value: 'app_callback' },
      { text: '留资返回弹窗', value: 'app_remain_callback' },
      { text: '产品页返回弹窗', value: 'app_product_detail_callback' },
    ],
    isOpen: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    withdrawalPage: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '动态',
        value: 1,
      },
      {
        text: '静态',
        value: 2,
      },
    ],
    bindCard: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '站内绑卡',
        value: 1,
      },
      {
        text: '三方页面绑卡',
        value: 3,
      },
    ],
    loanType: [
      {
        text: '首贷',
        value: 1,
      },
      {
        text: '复贷',
        value: 2,
      },
    ],
    apiOrderStatus: [
      {
        text: '提交申请',
        value: 0,
      },
      {
        text: '进件成功',
        value: 1,
      },
      {
        text: '进件失败',
        value: 2,
      },
      {
        text: '授信成功',
        value: 3,
      },
      {
        text: '授信失败',
        value: 4,
      },
      {
        text: '提现成功',
        value: 5,
      },
      {
        text: '提现失败',
        value: 6,
      },
      {
        text: '放款成功',
        value: 7,
      },
      {
        text: '放款失败',
        value: 8,
      },
      {
        text: '已结清',
        value: 9,
      },
      {
        text: '取消订单',
        value: 10,
      },
      {
        text: '紧急划回',
        value: 11,
      },
      {
        text: '额度失效',
        value: 30,
      },
      {
        text: '逾期',
        value: 31,
      },
      {
        text: '正常还款中',
        value: 32,
      },
    ],
    categorysList: [
      {
        text: 'H5产品',
        value: 1,
      },
      {
        text: '联登产品',
        value: 2,
      },
      {
        text: '信贷API产品',
        value: 3,
      },
    ],
    secondaryCategorysList: [
      {
        text: '助贷产品',
        value: 0,
      },
      {
        text: 'H5产品',
        value: 1,
      },
      {
        text: '联登产品',
        value: 2,
      },
      {
        text: '半流程信贷api产品',
        value: 31,
      },
      {
        text: '全流程信贷api产品',
        value: 32,
      },
    ],
    loginJumpType: [
      {
        text: '贷超页',
        value: 1,
      },
      {
        text: '下载页',
        value: 2,
      },
    ],
    showType: [
      {
        text: '每次展示',
        value: 1,
      },
      {
        text: '每天展示一次',
        value: 2,
      },
    ],
    productConfigTYpe: [
      {
        text: '默认配置',
        value: 0,
      },
      {
        text: '自定义配置',
        value: 1,
      },
    ],
    productWarningType: [
      {
        text: '产品撞库异常',
        value: 1,
      },
      {
        text: '申请-申请成功转化',
        value: 2,
      },
      {
        text: '申请成功-进件申请成功转',
        value: 3,
      },
    ],
    productWarningTypeOne: [
      {
        text: '撞库次数',
        value: 1,
      },
      {
        text: '申请uv',
        value: 2,
      },
      {
        text: '申请成功',
        value: 3,
      },
    ],
    productWarningTypeDesc: [
      {
        text: '监控指标：时段产品撞库异常数/撞库次数',
        value: 1,
      },
      {
        text: '监控指标：时段申请成功/申请uv',
        value: 2,
      },
      {
        text: '监控指标：信贷API进件申请成功/时段申请成功',
        value: 3,
      },
    ],
    productWarningTypeSigns: [
      {
        one: '>=',
        two: '<=',
        three: '<',
        four: '>=',
        value: 1,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 2,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 3,
      },
    ],
    channelWarningType: [
      {
        text: 'H5访登率',
        value: 1,
      },
      {
        text: 'H5端留资完成率',
        value: 2,
      },
      {
        text: '助贷匹配率',
        value: 3,
      },
      {
        text: '信贷api匹配率',
        value: 4,
      },
      {
        text: 'IOS激活率',
        value: 5,
      },
      {
        text: '安卓激活率',
        value: 6,
      },
      {
        text: '网贷登录申请率',
        value: 7,
      },
    ],
    channelWarningTypeOne: [
      {
        text: '访问uv',
        value: 1,
      },
      {
        text: '留资页访问UV',
        value: 2,
      },
      {
        text: '留资完成UV',
        value: 3,
      },
      {
        text: '留资完成UV',
        value: 4,
      },
      {
        text: 'H5登录总（ios）',
        value: 5,
      },
      {
        text: 'H5登录总（安卓）',
        value: 6,
      },
      {
        text: 'H5登录总',
        value: 7,
      },
    ],
    channelWarningTypeDesc: [
      {
        text: '监控指标：时段H5访登率',
        value: 1,
      },
      {
        text: '监控指标：时段留资完成UV/留资页访问UV',
        value: 2,
      },
      {
        text: '监控指标：时段助贷匹配率',
        value: 3,
      },
      {
        text: '监控指标：时段信贷api产品匹配率',
        value: 4,
      },
      {
        text: '监控指标：时段APP（IOS）登录总/H5登录总（IOS）',
        value: 5,
      },
      {
        text: '监控指标：时段APP（安卓）登录总/H5登录总（安卓）',
        value: 6,
      },
      {
        text: '监控指标：时段网贷登录申请率',
        value: 7,
      },
    ],
    channelWarningTypeSigns: [
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 1,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 2,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 3,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 4,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 5,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 6,
      },
      {
        one: '>=',
        two: '<',
        three: '<=',
        four: '<=',
        value: 7,
      },
    ],
    productSettlementType: [
      {
        text: '申请成功',
        value: 1,
      },
      {
        text: '回调成功',
        value: 2,
      },
    ],
    settlementField: [
      {
        text: '访问UV',
        value: 1,
      },
      {
        text: 'H5登录总',
        value: 2,
      },
      {
        text: '注册数(扣量)',
        value: 3,
      },
      {
        text: '联登总',
        value: 4,
      },
      {
        text: '进件成功UV',
        value: 5,
      },
      {
        text: '推送成功订单数',
        value: 6,
      },
      {
        text: '推送成功的订单金额',
        value: 7,
      },
      // {
      //   text: 'APP登录数',
      //   value: 8,
      // },
    ],
    priceType: [
      {
        text: '固定加价',
        value: 1,
      },
      {
        text: '按出价比例加价',
        value: 2,
      },
    ],
    advanceStatus: [
      {
        text: '正常',
        value: 0,
      },
      {
        text: '预付提醒',
        value: 1,
      },
    ],
    // 扣量节点
    deductionCode: [
      {
        text: '表单',
        value: 1,
      },
      {
        text: '匹配',
        value: 2,
      },
      {
        text: '进件',
        value: 3,
      },
    ],
    // 扣量类型
    deductionType: [
      {
        text: '系数扣量',
        value: 1,
      },
      {
        text: '价格扣量',
        value: 2,
      },
    ],
    // 扣量系数
    rationRatio: [
      {
        text: '10%',
        value: 10,
      },
      {
        text: '20%',
        value: 20,
      },
      {
        text: '30%',
        value: 30,
      },
      {
        text: '40%',
        value: 40,
      },
      {
        text: '50%',
        value: 50,
      },
      {
        text: '60%',
        value: 60,
      },
      {
        text: '70%',
        value: 70,
      },
      {
        text: '80%',
        value: 80,
      },
      {
        text: '90%',
        value: 90,
      },
      {
        text: '100%',
        value: 100,
      },
    ],

    noticeType: [
      {
        text: '正常短信',
        value: 1,
      },
      {
        text: '营销短信',
        value: 2,
      },
    ],
    filterTypeList: [
      {
        text: '选中资质任意满足其一',
        value: '1',
      },
      {
        text: '选中资质任意满足其二',
        value: '2',
      },
      {
        text: '选中资质任意满足其三',
        value: '3',
      },
      {
        text: '选中资质任意满足其四',
        value: '4',
      },
      {
        text: '选中资质任意满足其五',
        value: '5',
      },
      {
        text: '选中资质任意满足其六',
        value: '6',
      },
      {
        text: '选中资质任意满足其七',
        value: '7',
      },
      {
        text: '选中资质任意满足其八',
        value: '8',
      },
      {
        text: '选中资质任意满足其九',
        value: '9',
      },
      {
        text: '选中资质任意满足其十',
        value: '10',
      },
      {
        text: '选中资质任意满足其十一',
        value: '11',
      },
      {
        text: '选中资质任意满足其十二',
        value: '12',
      },
      {
        text: '选中资质任意满足其十三',
        value: '13',
      },
    ],
    filterTypeFiveList: [
      {
        text: '选中资质任意满足其一',
        value: 1,
      },
      {
        text: '选中资质任意满足其二',
        value: 2,
      },
      {
        text: '选中资质任意满足其三',
        value: 3,
      },
      {
        text: '选中资质任意满足其四',
        value: 4,
      },
      {
        text: '选中资质任意满足其五',
        value: 5,
      },
    ],
    orderStatus: [
      {
        text: '待推送',
        value: 0,
      },
      {
        text: '推送成功',
        value: 1,
      },
      {
        text: '推送失败',
        value: 2,
      },
    ],
    sendSmsType: [
      {
        text: '短信验证码',
        value: 1,
      },
      {
        text: '通知短信',
        value: 2,
      },
      {
        text: '营销短信',
        value: 3,
      },
    ],
    smsOperateStatus: [
      {
        text: '禁用',
        value: '0',
      },
      {
        text: '启用',
        value: '1',
      },
    ],
    smsOperateStatus2: [
      {
        text: '禁用',
        value: 0,
      },
      {
        text: '启用',
        value: 1,
      },
    ],
    smsSignType: [
      {
        text: '默认',
        value: 1,
      },
      {
        text: '优先APP应用签名、渠道签名、默认签名',
        value: 2,
      },
      // {
      //   text: 'APP应用签名',
      //   value: 3,
      // },
    ],
    duplicateType: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: '永久只发一次',
        value: 1,
      },
      {
        text: '按天去重发送',
        value: 2,
      },
    ],
    showClient: [
      {
        text: '不限',
        value: 0,
      },
      {
        text: '仅展示安卓',
        value: 2,
      },
      {
        text: '仅显示IOS',
        value: 3,
      },
    ],
    collisionStatus: [
      {
        text: '通过',
        value: 1,
      },
      {
        text: '不通过',
        value: 0,
      },
    ],
    multiRecommendType: [
      {
        text: '机构产品',
        value: 1,
      },
      {
        text: '全流程产品',
        value: 2,
      },
    ],
    osDevice: [
      {
        text: 'android',
        value: 'android',
      },
      {
        text: 'ios',
        value: 'ios',
      },
      {
        text: 'web',
        value: 'web',
      },
    ],
    organizationType: [
      {
        text: '全部机构',
        value: 1,
      },
      {
        text: '部分机构',
        value: 2,
      },
    ],
    issueType: [
      {
        text: '立即发布',
        value: 1,
      },
      {
        text: '定时发布',
        value: 2,
      },
    ],
    platformType: [
      {
        text: '未发布',
        value: 0,
      },
      {
        text: '已发布',
        value: 1,
      },
    ],
    starLevelList: [
      {
        text: '0星',
        value: 0,
      },
      {
        text: '1星',
        value: 1,
      },
      {
        text: '2星',
        value: 2,
      },
      {
        text: '3星',
        value: 3,
      },
      {
        text: '4星',
        value: 4,
      },
      {
        text: '5星',
        value: 5,
      },
    ],
    processType: [
      {
        text: '默认流程',
        value: 1,
      },
      {
        text: '自定义',
        value: 2,
      },
    ],
    blacklistSms: [
      {
        text: '不再接收短信验证码',
        value: 1,
      },
      {
        text: '不再接收通知短信',
        value: 2,
      },
      {
        text: '不再接收营销短信',
        value: 3,
      },
    ],
    operatorList: [
      {
        text: '移动',
        value: 1,
      },
      {
        text: '联通',
        value: 2,
      },
      {
        text: '电信',
        value: 3,
      },
      {
        text: '广电',
        value: 4,
      },
      {
        text: '其他',
        value: 5,
      },
    ],
    virtualNumberStatusList: [
      {
        text: '未完成',
        value: 0,
      },
      {
        text: '生成中',
        value: 1,
      },
      {
        text: '已完成',
        value: 2,
      },
      {
        text: '待删除',
        value: 3,
      },
      {
        text: '删除中',
        value: 4,
      },
    ],
    collisionFilterType: [
      {
        text: '按分数筛选',
        value: 1,
      },
      {
        text: '按撞库筛选',
        value: 2,
      },
    ],
    illegalTypeList: [
      {
        text: '精准匹配',
        value: 1,
      },
      {
        text: '包含关系',
        value: 2,
      },
    ],
    signStatus: [
      {
        text: '禁用',
        value: 0,
      },
      {
        text: '启用',
        value: 1,
      },
    ],
    signName: [
      {
        text: 'e签宝',
        value: 10,
      },
      {
        text: '法大大',
        value: 20,
      },
    ],
    successUrlList: (val: number | string) => {
      const arr1 = [
        {
          text: '二次推荐页',
          value: 'creditApiApplyAgain',
        },
        {
          text: '申请成功页',
          value: 'creditApiApplySuccess',
        },
      ];
      const arr2 = [
        {
          text: '申请成功页+客服电话',
          value: 'applySuccess_phone',
        },
        {
          text: '申请成功页+微信',
          value: 'applySuccess_wx',
        },
        {
          text: '申请成功页+客服电话+微信',
          value: 'applySuccess_phone_wx',
        },
        {
          text: '三方链接',
          value: 'applySuccess_three_url',
        },
      ];
      if (Number(val) === 2 || !val) {
        return arr2;
      } else {
        return arr1;
      }
    },
    feeTypeList: [
      {
        text: '申请扣费',
        value: 1,
      },
      {
        text: '跳转企微或企业电话扣费',
        value: 2,
      },
    ],
  },
};

export default adminConfig;
