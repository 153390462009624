<template>
  <!--  <ul class="roleChoose">-->
  <!--    <li :class="state.isMaster === 0 ? 'active' : ''" @click="state.isMaster = 0">后台人员登录</li>-->
  <!--    <li :class="state.isMaster === 1 ? 'active' : ''" @click="state.isMaster = 1">代理商登录</li>-->
  <!--  </ul>-->
  <el-form
    ref="loginForm"
    :model="state.form"
    :rules="state.rules"
    @keydown.enter="submitForm(loginForm)"
  >
    <el-form-item prop="userName">
      <el-input
        v-model="state.form.userName"
        placeholder="请输入手机号"
        :maxlength="11"
        :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
        :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
      >
        <template #prepend>
          <img src="@/assets/images/phone.png" alt="" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="state.form.password"
        placeholder="请输入验证码"
        :maxlength="4"
        :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
        :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
      >
        <template #prepend>
          <img src="@/assets/images/pwd.png" alt="" />
        </template>
        <template #append>
          <p style="cursor: pointer" v-show="state.show" @click="getSms()">获取验证码</p>
          <p style="cursor: pointer" v-show="!state.show">{{ state.counts }}S后重新获取</p>
        </template>
      </el-input>
    </el-form-item>
    <el-button @click="submitForm(loginForm)" type="primary" class="submit">登录</el-button>
  </el-form>
</template>

<script lang="ts" setup>
  import { ElMessage } from 'element-plus';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import type { FormInstance } from 'element-plus';
  import { Auth, ChannelBusiness } from '@/libs/requestAddress';

  const emit = defineEmits(['on-success-valid']);
  const validatePhone = (rule: any, value: string, callback: any) => {
    if (!value) {
      return callback(new Error('手机号不能为空'));
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      callback('手机号格式不正确');
    } else {
      callback();
    }
  };
  let timer: number | null = null;
  const state = reactive({
    show: true,
    counts: 60,
    isMaster: 0,
    form: {
      userName: '',
      password: '',
    },
    rules: {
      userName: [
        {
          validator: validatePhone,
          trigger: 'blur',
        },
      ],
      password: [
        {
          message: '验证不能为空',
          required: true,
          trigger: 'blur',
        },
      ],
    },
  });

  const loginForm = ref<FormInstance>();

  const submitForm = (formEl: FormInstance | undefined | any) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        let user = {
          phone: state.form.userName,
          smsCode: state.form.password, // smsCode
        };
        emit('on-success-valid', user, state.isMaster);
      }
    });
  };
  const getSms = basics.debounce(function () {
    if (/^1[3456789]\d{9}$/.test(state.form.userName)) {
      let jsonPhone = { phone: state.form.userName };
      const _url = state.isMaster === 0 ? Auth.sendSms : ChannelBusiness.sendSms;
      http.get(_url, jsonPhone).then(() => {
        getCode();
      });
    } else {
      ElMessage.error('手机号码错误');
    }
  }, 500);

  const getCode = () => {
    const TIME_COUNT = 60;
    if (!timer) {
      state.counts = TIME_COUNT;
      state.show = false;
      timer = setInterval(() => {
        if (state.counts > 0 && state.counts <= TIME_COUNT) {
          state.counts--;
        } else {
          state.show = true;
          clearInterval(timer as number);
          timer = null;
        }
      }, 1000);
    }
  };
  watch(
    () => state.isMaster,
    () => {
      state.form.userName = '';
      state.form.password = '';
      state.show = true;
      clearInterval(timer as number);
      timer = null;
    }
  );
</script>
<style lang="less">
  @import '@/views/login/login.less';
</style>
